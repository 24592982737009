import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, Event  } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    loading: boolean = false;
    constructor(private router: Router) {
        this.router.events.subscribe((routerEvent: Event) => {
            if(routerEvent instanceof NavigationStart) {
              this.loading = true;
            }
            if(routerEvent instanceof NavigationEnd) {
              this.loading = false;
              window.scrollTo(0, 0);
            }
          })
    }

    ngOnInit() {
    }
}

import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as decode from 'jsonwebtoken';
const baseUrl = environment.baseUrl;
@Injectable({
    providedIn: "root"
})
export class AuthService {
    constructor(private http: HttpClient) {
    }
    registerUser(user) {
       return this.http.post(baseUrl + '/register', user, {observe: 'response'});
    }
    loginUser(user) {
        return this.http.post(baseUrl + '/adminlogin', user, {observe: 'response'});
    }
    getFullToken() {
        return localStorage.getItem("atapi_token");
    }
    getToken() {
        return !!localStorage.getItem("atapi_token");
    }
    sendEmail(user) {
        return this.http.post(baseUrl + '/forgot/password', user);
    }
    resetPassword(id, user) {
        return this.http.post(baseUrl + '/forgot/password/reset/' + id, user);
    }
    autoLockUser(phone) {
        return this.http.get(baseUrl + '/auto/lock/' + phone,);
    }
}

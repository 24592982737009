import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http'
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private auth: AuthService, private toastr: ToastrService, public router: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let tokenizedReq = req.clone({
      setHeaders: {
        Authorization: this.auth.getFullToken() ? `Bearer ${this.auth.getFullToken()}` : '',
        'X-CSRF-TOKEN': this.auth.getFullToken() ? `${this.auth.getFullToken()}` : '',
        Accept: 'application/json'
      }
    });
    return next.handle(tokenizedReq).pipe(tap(event => { }, err => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        console.log(err);
        if (err.error.detail) {
          this.toastr.error(err.error.detail);
        }
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      else if (err instanceof HttpErrorResponse && err.status === 400) {
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }return false;
      }
    }));
  }
}
